import React, { useState } from "react";
import Player from "./Player.jsx";
import { useForm } from "react-hook-form";
import "./Home.css";
import elabslogo from "../assets/elabslogo.png";
import kiitlogo from "../assets/KIIT_logo.png";
import AlertBox, { AlertBoxTypes, changeAlertBoxState } from "./AlertBox.jsx";
import RLogo from "../assets/rebootLogo.png";
import ContactModal from "./ContactModal.jsx";
import SubmitModal from "./SubmitModal.jsx";

const Home = () => {
    const {
        formState: { errors },
        handleSubmit,
    } = useForm({ mode: "onBlur" });

    const [alertBoxState, setAlertBoxState] = useState({
        open: false,
        type: AlertBoxTypes.SUCCESS,
        message: "",
        title: null,
    });

    var members = [];
    const onSubmit = async (data) => {
        members.push(player1);
        members.push(player2);

        if (teamSize === "3") {
            members.push(player3);
        } else if (teamSize === "4") {
            members.push(player3);
            members.push(player4);
        }
        data.members = members;
        data.teamName = teamName;
        data.teamSize = teamSize;

        const apiUrl = process.env.REACT_APP_SERVER_URL + "/team/new";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        const response = await fetch(apiUrl, requestOptions);
        const result = await response.json();

        if (result.success === true) {
            changeAlertBoxState(
                AlertBoxTypes.SUCCESS,
                "Success",
                "Team Registered Successfully",
                setAlertBoxState
            );
            setSubmitModalState(true);
        } else {
            if (result.error === "Error while saving participants") {
                changeAlertBoxState(
                    AlertBoxTypes.ERROR,
                    "Error",
                    "Player(s) already registered",
                    setAlertBoxState
                );
            } else if (result.error === "Database unresponsive") {
                changeAlertBoxState(
                    AlertBoxTypes.ERROR,
                    "Error",
                    "Error while creating team. Server Issue. Please try again.",
                    setAlertBoxState
                );
            }
            else {
             changeAlertBoxState(
                AlertBoxTypes.ERROR,
                "Error",
                result.error,
                setAlertBoxState
            );   
            }
        }
    };

    const [player1, setPlayer1] = useState({
        name: "",
        year: "",
        email: "",
        phoneNumber: "",
        branch: "",
        whatsappNumber: "",
    });

    const [player2, setPlayer2] = useState({
        name: "",
        year: "",
        email: "",
        phoneNumber: "",
        branch: "",
        whatsappNumber: "",
    });

    const [player3, setPlayer3] = useState({
        name: "",
        year: "",
        email: "",
        phoneNumber: "",
        branch: "",
        whatsappNumber: "",
    });

    const [player4, setPlayer4] = useState({
        name: "",
        year: "",
        email: "",
        phoneNumber: "",
        branch: "",
        whatsappNumber: "",
    });

    const [teamName, setTeamName] = useState("");
    const [teamSize, setTeamSize] = useState(null);

    const handleSubmitClick = (e) => {
        if (!teamName || teamName === "") {
            changeAlertBoxState(
                AlertBoxTypes.ERROR,
                "Error",
                "Team Name is required",
                setAlertBoxState
            );
            return;
        }
        handleSubmit(onSubmit);
    };

    const [contactModalState, setContactModalState] = useState(false);
    const [submitModalState, setSubmitModalState] = useState(false);
    const handleContactModalOpen = () => {
        setContactModalState(true);
    };
    const handleSubmitModalClose = () => {
        setSubmitModalState(false);
    };

    const handleContactModalClose = () => {
        setContactModalState(false);
    };

    return (
        <>
            <div className="main__container">
                <div className="main__title">
                    <img src={elabslogo} className="elabslogo" alt="" />
                    <img src={RLogo} className="Rlogo" alt="" />
                    {/* <h1>rE-Boot</h1> */}
                    <img src={kiitlogo} className="kiitlogo" alt="" />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="main__form">
                        <div className="mainsubform__title">
                            <h1>Register For Event</h1>
                        </div>
                        <div className="mainform__input">
                            <span>
                                <label htmlFor="name">Team Name :</label>
                                <input
                                    type="text"
                                    id="teamname"
                                    className="mainform__input__details"
                                    value={teamName}
                                    onChange={(e) =>
                                        setTeamName(e.target.value)
                                    }
                                    placeholder="Enter team name"
                                    required
                                />
                            </span>
                            <span>
                                <label htmlFor="team">Team Size :</label>
                                <select
                                    id="team"
                                    className="mainform__input__details"
                                    value={teamSize}
                                    onChange={(e) =>
                                        setTeamSize(e.target.value)
                                    }
                                >
                                    <option
                                        value="2"
                                        className="mainform__input"
                                    >
                                        2
                                    </option>
                                    <option
                                        value="3"
                                        className="mainform__input"
                                    >
                                        3
                                    </option>
                                    <option
                                        value="4"
                                        className="mainform__input"
                                    >
                                        4
                                    </option>
                                </select>

                                {errors.team && <p>{errors.team.message}</p>}
                            </span>
                        </div>
                        <div className="player__container">
                            <Player
                                id="1"
                                name="player1"
                                label="Player 1"
                                value={player1}
                                setValue={setPlayer1}
                            />
                            <Player
                                id="2"
                                name="player2"
                                label="Player 2"
                                value={player2}
                                setValue={setPlayer2}
                            />
                            {teamSize > 2 && teamSize < 4 ? (
                                <Player
                                    id="3"
                                    name="player3"
                                    label="Player 3"
                                    value={player3}
                                    setValue={setPlayer3}
                                />
                            ) : null}
                        </div>

                        {teamSize > 3 ? (
                            <div className="player__container">
                                <Player
                                    id="3"
                                    name="player3"
                                    label="Player 3"
                                    value={player3}
                                    setValue={setPlayer3}
                                />
                                <Player
                                    id="4"
                                    name="player4"
                                    label="Player 4"
                                    value={player4}
                                    setValue={setPlayer4}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="mainform__submit">
                        <h1 onClick={handleContactModalOpen}>Contact Us</h1>
                        <button
                            // type="submit"
                            className="mainform__submit__button"
                            onClick={handleSubmitClick}
                        >
                            REGISTER
                        </button>
                    </div>
                </form>
            </div>
            <ContactModal
                open={contactModalState}
                closeModal={handleContactModalClose}
            />
            <SubmitModal
                open={submitModalState}
                closeModal={handleSubmitModalClose}
            />
            <AlertBox
                type={alertBoxState.type}
                title={alertBoxState.title}
                message={alertBoxState.message}
                state={alertBoxState.open}
                handleState={setAlertBoxState}
            />
        </>
    );
};

export default Home;
