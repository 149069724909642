import React from "react";
import { Button, Dialog } from "@mui/material";
import "./SubmitModal.css";

function SubmitModal({ open, closeModal }) {
    const closeModalHandler = () => {
        closeModal();
    };

    return (
        <>
            <Dialog open={open} onClose={closeModalHandler} fullWidth>
                <div className="submit__modal">
                    <h1>Thank you for your submission!</h1>
                    <h3>We will be in touch shortly.</h3>
                    <h4>Mail will be sent to all your teammates.</h4>
                </div>
                <Button
                    onClick={closeModalHandler}
                    variant="contained"
                    disableElevation
                    color="error"
                >
                    Close
                </Button>
            </Dialog>
        </>
    );
}

export default SubmitModal;
