const branchList = [
    "ETC",
    "EEE",
    "EE",
    "ECS",
    "EI",
    "MECHANICAL",
    "CIVIL",
    "CSE",
    "IT",
    "CSSE",
    "CSCE",
    "BCA",
    "Others",
];

export default branchList;
