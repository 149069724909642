import React from "react";
import { useForm } from "react-hook-form";
import branchList from "../static/branchList.js";
import "./Player.css";

const Player = ({ id, label, name, value, setValue }) => {
    const {
        formState: { errors },
        register,
    } = useForm({ mode: "onBlur" });

    return (
        <div className="subform__container">
            <div className="subform__title">
                <h2>{label}</h2>
            </div>
            <div className="form__input">
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    placeholder={`Enter ${label} name`}
                    className="form__input__details"
                    onChange={(e) => {
                        setValue({
                            ...value,
                            name: e.target.value,
                        });
                    }}
                    required
                />
            </div>

            <div className="form__input_dropdown">
                <label htmlFor="branch">Year</label>
                <select
                    className="form__dropdown"
                    name="branch"
                    onChange={(e) => {
                        setValue({
                            ...value,
                            year: e.target.value,
                        });
                    }}
                >
                    <option value="" className="form__dropdown__option">
                        Year
                    </option>
                    <option value="1st">1st</option>
                    <option value="2nd">2nd</option>
                    <option value="3rd">3rd</option>
                    <option value="4th">4th</option>
                </select>
            </div>

            <div className="form__input_dropdown">
                <label htmlFor="branch">Branch</label>
                <select
                    className="form__dropdown"
                    name="branch"
                    onChange={(e) => {
                        setValue({
                            ...value,
                            branch: e.target.value,
                        });
                    }}
                >
                    <option value="Others" className="form__dropdown__option">
                        Select your branch
                    </option>
                    {branchList.map((branch) => (
                        <option
                            value={branch}
                            key={branch}
                            className="form__dropdown__option"
                        >
                            {branch}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form__input">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    className="form__input__details"
                    placeholder="Enter your kiit email"
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: /^[0-9]{7,8}@kiit.ac.in/,
                            message: "Enter a valid kiit email",
                        },
                    })}
                    onChange={(e) => {
                        setValue({ ...value, email: e.target.value });
                    }}
                />
                {errors.email && <p>{errors.email.message}</p>}
            </div>

            <div className="form__input">
                <label htmlFor="phone">Phone Number</label>
                <input
                    type="tel"
                    id="phone"
                    className="form__input__details"
                    placeholder="Enter your phone number"
                    {...register("phone", {
                        required: "Phone number is required",
                        pattern: {
                            value: /^\d{10}$/,
                            message: "Enter a valid phone number",
                        },
                    })}
                    onChange={(e) => {
                        setValue({ ...value, phoneNumber: e.target.value });
                    }}
                />
                {errors.phone && <p>{errors.phone.message}</p>}
            </div>

            <div className="form__input">
                <label htmlFor="wp">Whatsapp</label>
                <input
                    type="tel"
                    id="wp"
                    className="form__input__details"
                    onChange={(e) => {
                        setValue({ ...value, whatsappNumber: e.target.value });
                    }}
                    placeholder="Enter your whatsapp number"
                    {...register("whatsappNumber", {
                        pattern: {
                            value: /^\d{10}$/,
                            message: "Enter a valid number",
                        },
                    })}
                />
                {errors.whatsappNumber && (
                    <p>{errors.whatsappNumber.message}</p>
                )}
            </div>
        </div>
    );
};

export default Player;
