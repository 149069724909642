import React from "react";
import { Dialog } from "@mui/material";
import "./ContactModal.css";
import { WhatsApp } from "@mui/icons-material";

const ContactModal = ({ open, closeModal }) => {
    return (
        <>
            <Dialog open={open} onClose={closeModal} fullWidth>
                <div className="contact__modal">
                    {/* contact details of  */}
                    <h1>Facing Problem? Contact Us</h1>

                    <br></br>
                    <h3>
                        Call us at <b>8580261817</b>
                    </h3>

                    <h3>Or WhatsApp us at :</h3>
                    <span className="mail__container">
                        <WhatsApp />
                        <a href="whatsapp://send?text=Hello my team has registered for reboot but &phone=+918580261817">
                            Co-ordinator: Shreya Raj
                        </a>
                    </span>
                    <span className="mail__container">
                        <WhatsApp />
                        <a href="whatsapp://send?text=Hello my team has registered for reboot but&phone=+917800757322">
                            Assistant Co-ordinator: Harsh
                        </a>
                    </span>
                </div>
            </Dialog>
        </>
    );
};

export default ContactModal;
